import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: '首页',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/indexPage',
        name: '首页',
        component: () => import('@/views/index.vue')
    },
    {
        path: '/about',
        name: '关于我们',
        component: () => import('@/views/about.vue')
    },
   
    {
        path: '/coreTechnologies',
        name: 'coreTechnologies',
        component: () => import('@/views/coreTechnologies')
    },
   
    {
        path: '/project',
        name: 'project',
        component: () => import('@/views/project')
    },
   
    // {
    //     path: '/',
    //     name: 'redBook',
    //     component: () => import('@/views/redBook/App.vue')
    // },
    
]


const router = new VueRouter({
    routes
})
// 获取原型对象push函数
const originalPush = VueRouter.prototype.push

// 获取原型对象replace函数
const originalReplace = VueRouter.prototype.replace

// 修改原型对象中的push函数
VueRouter.prototype.push = function push(location){
return originalPush.call(this , location).catch(err=>err)
}

// 修改原型对象中的replace函数
VueRouter.prototype.replace = function replace(location){
return originalReplace.call(this , location).catch(err=>err)
}
export default router
