import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "./utils/rem.js"
import '@/assets/cssNew/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import FastClick from 'fastclick'
import animated from 'animate.css' 
import VideoPlayer from 'vue-video-player'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
import global from "./global.js"

Vue.use(VideoPlayer)
Vue.use(animated)
// FastClick.attach(document.body);
Vue.use(ElementUI);

import { vueBaberrage, MESSAGE_TYPE } from 'vue-baberrage';
Vue.use(vueBaberrage);
Vue.config.productionTip = false

Vue.prototype.Global=global;

router.beforeEach((to, form, next)=>{
    if(to.meta.title){
        document.title = to.meta.title;
    }
    next();
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
